var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-2 pa-2" },
    [
      _c(
        "v-expansion-panels",
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _vm._v(" Configurações de Mensageria ")
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "ma-3 pa-3" },
                        [
                          _c("v-card-text", [
                            _vm._v(
                              " Configurações do Envio de E-mails Automáticos do Sistema "
                            )
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12", lg: "3" } },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-information-outline")
                                      ]),
                                      _vm._v(" Status 102 - Protestado ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "8"
                                  }
                                },
                                [
                                  _c("v-slider", {
                                    staticClass: "mt-3",
                                    attrs: {
                                      min: "7",
                                      "persistent-hint": "",
                                      "thumb-label": "always",
                                      label: "Dias de Intervalo:",
                                      max: "100"
                                    },
                                    model: {
                                      value: _vm.diasLembrete102,
                                      callback: function($$v) {
                                        _vm.diasLembrete102 = $$v
                                      },
                                      expression: "diasLembrete102"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.superUsuario
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", md: "12", lg: "3" }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-information-outline")
                                          ]),
                                          _vm._v(
                                            " Nome de exibição nos emails transacionais "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", md: "12", lg: "4" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "ma-3",
                                        attrs: {
                                          solo: "",
                                          disabled: _vm.isPartner,
                                          placeholder: "Nome de Exibição",
                                          hint:
                                            "Digite aqui o nome de exibição que será inserido nos emails transacionais"
                                        },
                                        model: {
                                          value: _vm.nomeExibicaoApresentante,
                                          callback: function($$v) {
                                            _vm.nomeExibicaoApresentante = $$v
                                          },
                                          expression: "nomeExibicaoApresentante"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.superUsuario
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", md: "12", lg: "3" }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-information-outline")
                                          ]),
                                          _vm._v(" Link de contato ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", md: "12", lg: "4" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "ma-3",
                                        attrs: {
                                          solo: "",
                                          disabled: _vm.isPartner,
                                          placeholder: "Link de Contato",
                                          hint:
                                            "Digite aqui o endereço que será inserido nos botões de contato"
                                        },
                                        model: {
                                          value: _vm.linkContatoApresentante,
                                          callback: function($$v) {
                                            _vm.linkContatoApresentante = $$v
                                          },
                                          expression: "linkContatoApresentante"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                lg: "10"
                              }
                            },
                            [
                              _c("v-card-text", { staticClass: "grey--text" }, [
                                _vm._v(
                                  " Selecione no campo acima o intervalo de dias em que nosso sistema fará o envio de e-mails automáticos. Por exemplo: 10 dias, Quando o titulo é protestado, o devedor receberá e-mails automáticos frequentemente a cada 10 dias. "
                                )
                              ]),
                              _vm.snackbarResponse
                                ? _c("Snackbar", {
                                    attrs: {
                                      mostrarSnackbar: _vm.snackbarResponse,
                                      corSnackbar: _vm.tipoSnackbar,
                                      mensagemSnackbar: _vm.dialogResponse
                                    },
                                    on: {
                                      fecharSnackbar: function($event) {
                                        _vm.snackbarResponse = false
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.loading
                                      },
                                      on: { click: _vm.salvaDadosApresentante }
                                    },
                                    [
                                      _vm._v("Salvar"),
                                      _c("v-icon", [
                                        _vm._v(" mdi-chevron-right")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }