var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c(
                "form",
                { ref: "usuarioForm" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-auto text-center",
                      attrs: { sm: "6", md: "6", lg: "5" }
                    },
                    [
                      _c("v-icon", { attrs: { size: "150" } }, [
                        _vm._v("mdi-playlist-plus")
                      ]),
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "primary--text font-weight-black display-1"
                        },
                        [_vm._v(" Editar Apresentante ")]
                      ),
                      _c("v-card-text", {}, [
                        _vm._v(
                          " Siga os passos ao lado para Editar um apresentante "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "6", md: "6", lg: "6" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "my-6" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Número do CNPJ ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "8", md: "7" } },
                                    [
                                      _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "XX.XXX.XXX/XXXX-##",
                                            expression: "'XX.XXX.XXX/XXXX-##'"
                                          }
                                        ],
                                        attrs: {
                                          solo: "",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          name: "cnpj",
                                          label: "Exemplo: 00.000.000/0000-00",
                                          rules: [
                                            _vm.rules.required,
                                            _vm.rules.validarCNPJ
                                          ],
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.apresentante.nu_cnpj,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "nu_cnpj",
                                              $$v
                                            )
                                          },
                                          expression: "apresentante.nu_cnpj"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Código do Apresentante ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "8", md: "7" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          filled: "",
                                          disabled: "",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          name: "cd_apresentante",
                                          label: "Campo não editável",
                                          max: "3",
                                          rules: _vm.nameRulesConfirmation,
                                          required: ""
                                        },
                                        model: {
                                          value:
                                            _vm.apresentante.cd_apresentante,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "cd_apresentante",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                            apresentante.cd_apresentante\n                                        "
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Nome de Exibição ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "7" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          solo: "",
                                          label: "Nome de exibição",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          name: "nome_exibicao",
                                          type: "text",
                                          rules: _vm.nameRulesConfirmation
                                        },
                                        model: {
                                          value: _vm.apresentante.nome_exibicao,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "nome_exibicao",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "apresentante.nome_exibicao"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Razão Social ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "7", md: "7" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          solo: "",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          name: "nm_razao_social",
                                          label: "Razão Social",
                                          rules: _vm.nameRulesConfirmation,
                                          required: ""
                                        },
                                        model: {
                                          value:
                                            _vm.apresentante.nm_razao_social,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "nm_razao_social",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                            apresentante.nm_razao_social\n                                        "
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Data de liberação estados ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "7", md: "7" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "menu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "auto"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            value: _vm.formatDate(
                                                              _vm.apresentante
                                                                .dt_liberacao_estados
                                                            ),
                                                            "prepend-icon":
                                                              "mdi-calendar",
                                                            readonly: ""
                                                          }
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.menuDate,
                                            callback: function($$v) {
                                              _vm.menuDate = $$v
                                            },
                                            expression: "menuDate"
                                          }
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: {
                                              locale: "pt-br",
                                              "active-picker": _vm.activePicker
                                            },
                                            on: {
                                              "update:activePicker": function(
                                                $event
                                              ) {
                                                _vm.activePicker = $event
                                              },
                                              "update:active-picker": function(
                                                $event
                                              ) {
                                                _vm.activePicker = $event
                                              },
                                              input: function($event) {
                                                _vm.menuDate = false
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.apresentante
                                                  .dt_liberacao_estados,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.apresentante,
                                                  "dt_liberacao_estados",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "apresentante.dt_liberacao_estados"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Link de contato ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "7", md: "7" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Link de contato",
                                          solo: "",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          name: "linkContato"
                                        },
                                        model: {
                                          value: _vm.apresentante.link_contato,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "link_contato",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "apresentante.link_contato"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("E-mail Apresentante ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "7", md: "7" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "E-mail de contato",
                                          solo: "",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          name: "emailApresentante"
                                        },
                                        model: {
                                          value:
                                            _vm.apresentante.email_apresentante,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "email_apresentante",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                            apresentante.email_apresentante\n                                        "
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "6" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-switch", {
                                            staticClass: "mt-0",
                                            attrs: {
                                              outlined: "",
                                              color: "success",
                                              label: "Ativo Comando",
                                              required: "",
                                              name: "ativoComando",
                                              "data-cy": "ativoComando",
                                              "hide-details": "auto"
                                            },
                                            model: {
                                              value:
                                                _vm.apresentante
                                                  .st_ativo_comando,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.apresentante,
                                                  "st_ativo_comando",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                                                apresentante.st_ativo_comando\n                                            "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "6" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-switch", {
                                            staticClass: "mt-0",
                                            attrs: {
                                              outlined: "",
                                              color: "success",
                                              label: "Ativo Remessa",
                                              required: "",
                                              name: "ativoRemessa",
                                              "data-cy": "ativoRemessa",
                                              "hide-details": "auto"
                                            },
                                            model: {
                                              value:
                                                _vm.apresentante
                                                  .st_ativo_remessa,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.apresentante,
                                                  "st_ativo_remessa",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                                                apresentante.st_ativo_remessa\n                                            "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex pt-0",
                                      attrs: { sm: "6", md: "6" }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "font-weight-light d-flex"
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on, attrs }) {
                                                    return [
                                                      _c("v-switch", {
                                                        staticClass: "mt-0",
                                                        attrs: {
                                                          outlined: "",
                                                          color: "success",
                                                          label: "Está ativo?",
                                                          required: "",
                                                          "data-cy":
                                                            "btnStatusApresentante",
                                                          "hide-details": "auto"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.apresentante
                                                              .st_ativo,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.apresentante,
                                                              "st_ativo",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                                                            apresentante.st_ativo\n                                                        "
                                                        }
                                                      }),
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "ml-3",
                                                              attrs: {
                                                                color:
                                                                  "grey lighten-2"
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" help ")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Selecione essa opção para definir se o usuário está ativo. Caso o usuário estiver inativo, não irá conseguir acessar o sistema. "
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex pt-0",
                                      attrs: { sm: "6", md: "6" }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "font-weight-light d-flex"
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function({ on, attrs }) {
                                                    return [
                                                      _c("v-switch", {
                                                        staticClass: "mt-0",
                                                        attrs: {
                                                          outlined: "",
                                                          color: "success",
                                                          label: "CRA Nacional",
                                                          required: "",
                                                          name: "cra_nacional",
                                                          "data-cy":
                                                            "craNacional",
                                                          "hide-details": "auto"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.apresentante
                                                              .cra_nacional,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.apresentante,
                                                              "cra_nacional",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                                                        apresentante.cra_nacional\n                                                    "
                                                        }
                                                      }),
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "ml-3",
                                                              attrs: {
                                                                color:
                                                                  "grey lighten-2"
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" help ")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Selecione essa opção para definir se o apresentante é pertencente ao CRA Nacional. "
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "6", md: "6", lg: "6" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "my-6" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Partner ID ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "7", md: "7" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "data-cy": "partnerSelect",
                                          items: _vm.partners,
                                          clearable: "",
                                          label: "Selecione o Parter ID",
                                          "item-text": "fullname",
                                          "item-value": "id",
                                          name: "partner",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          filled: "",
                                          type: "number"
                                        },
                                        model: {
                                          value: _vm.apresentante.partner_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "partner_id",
                                              $$v
                                            )
                                          },
                                          expression: "apresentante.partner_id"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Tipo do Apresentante ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "7", md: "7" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.listaTiposApresentante,
                                          solo: "",
                                          "item-text": "cd_tipo_apresentante",
                                          "item-value": "id_tipo_apresentante",
                                          name: "tipo",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          label: "Tipo Apresentante",
                                          required: "",
                                          "data-cy": "apresentanteTipo"
                                        },
                                        model: {
                                          value:
                                            _vm.apresentante
                                              .id_tipo_apresentante,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "id_tipo_apresentante",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                            apresentante.id_tipo_apresentante\n                                        "
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("URL endpoint ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "8", md: "7" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          solo: "",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          name: "url_endpoint",
                                          label:
                                            "Exemplo: https://endereco.com.br/exemplo",
                                          "data-cy": "urlEndpoint"
                                        },
                                        model: {
                                          value:
                                            _vm.apresentante
                                              .callback_url_endpoint,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "callback_url_endpoint",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                            apresentante.callback_url_endpoint\n                                        "
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Callback Certificado ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "8", md: "7" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          solo: "",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          name: "certificado_apresentante",
                                          label:
                                            "Informe o certificado do Apresentante",
                                          "data-cy": "certificado"
                                        },
                                        model: {
                                          value:
                                            _vm.apresentante
                                              .callback_certificate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "callback_certificate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                            apresentante.callback_certificate\n                                        "
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Chave Callback ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "8", md: "7" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          solo: "",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          name: "key_apresentante",
                                          label:
                                            "Informe a chave do apresentante",
                                          "data-cy": "certificado"
                                        },
                                        model: {
                                          value: _vm.apresentante.callback_key,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "callback_key",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "apresentante.callback_key"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "6", md: "4" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "font-weight-light" },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-chevron-right")
                                          ]),
                                          _vm._v("Parametros callback JWT ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "8", md: "7" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          solo: "",
                                          "prepend-icon":
                                            "mdi-information-outline",
                                          name: "callback_jwt_request_params",
                                          label:
                                            "Informe o usuário de autenticação callback",
                                          "data-cy":
                                            "callback_jwt_request_params"
                                        },
                                        model: {
                                          value:
                                            _vm.apresentante
                                              .callback_jwt_request_params,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.apresentante,
                                              "callback_jwt_request_params",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                            apresentante.callback_jwt_request_params\n                                        "
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.ativoProtestado
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mx-auto",
                              staticStyle: { display: "flex" },
                              attrs: { sm: "6", md: "6" }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mx-3",
                                  attrs: { heigth: "100%", color: "green" }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "white--text" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-3",
                                          attrs: {
                                            small: "",
                                            fab: "",
                                            color: "green lighten-2 white--text"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "white--text" },
                                            [_vm._v("mdi-information")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(
                                        "Apresentante não habilitado na Protestado. "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "white--text" },
                                    [
                                      _vm._v(
                                        " Clique no botão abaixo para habilitar apresentante na plataforma Protestado "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "white--text" },
                                    [
                                      _vm._v(
                                        " Ao confirmar, verifique os dados de integração que serão gerados. "
                                      )
                                    ]
                                  ),
                                  !_vm.ativoProtestado
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "d-flex justify-center ma-3",
                                          attrs: { loading: _vm.loading },
                                          on: {
                                            click:
                                              _vm.ativaApresentanteProtestado
                                          }
                                        },
                                        [_vm._v(" Habilitar Apresentante ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.ativoProtestado
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mx-auto",
                              staticStyle: { display: "flex" },
                              attrs: { sm: "6", md: "6" }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  attrs: {
                                    width: "100%",
                                    color: "green white--text"
                                  }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-3",
                                          attrs: { small: "", fab: "" }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-check")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(
                                        " Esse é um apresentante ativo na Protestado "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-3 px-3" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "4" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass: "font-weight-light"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "white" } },
                                                [_vm._v("mdi-chevron-right")]
                                              ),
                                              _vm._v("Protestado UUID ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "8" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "Id do Apresentante na Protestado(UUID)",
                                              solo: "",
                                              readonly: "",
                                              "prepend-icon":
                                                "mdi-information-outline white--text",
                                              name: "protestadoUuid",
                                              hint: "Informações não editáveis"
                                            },
                                            model: {
                                              value:
                                                _vm.apresentanteEntidade.id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.apresentanteEntidade,
                                                  "id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "apresentanteEntidade.id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-3 px-3" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "4" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass: "font-weight-light"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "white" } },
                                                [_vm._v("mdi-chevron-right")]
                                              ),
                                              _vm._v("Nome do Apresentante ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "8" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "Nome do Apresentante na Protestado",
                                              solo: "",
                                              readonly: "",
                                              "prepend-icon":
                                                "mdi-information-outline white--text",
                                              name: "protestadoName",
                                              hint: "Informações não editáveis"
                                            },
                                            model: {
                                              value:
                                                _vm.apresentanteEntidade.name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.apresentanteEntidade,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "apresentanteEntidade.name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-3 px-3" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "4" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass: "font-weight-light"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "white" } },
                                                [_vm._v("mdi-chevron-right")]
                                              ),
                                              _vm._v("Data de Criação ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "8" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Data de Criação",
                                              solo: "",
                                              readonly: "",
                                              "prepend-icon":
                                                "mdi-information-outline white--text",
                                              name: "created_at_protestado",
                                              hint: "Informações não editáveis"
                                            },
                                            model: {
                                              value: _vm.computedDataCriacao,
                                              callback: function($$v) {
                                                _vm.computedDataCriacao = $$v
                                              },
                                              expression: "computedDataCriacao"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-3 px-3" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "4" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass: "font-weight-light"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "white" } },
                                                [_vm._v("mdi-chevron-right")]
                                              ),
                                              _vm._v("Documento CPF/CNPJ ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "8" } },
                                        [
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: [
                                                  "###.###.###-##",
                                                  "XX.XXX.XXX/XXXX-##"
                                                ],
                                                expression:
                                                  "[\n                                            '###.###.###-##',\n                                            'XX.XXX.XXX/XXXX-##'\n                                        ]"
                                              }
                                            ],
                                            attrs: {
                                              label: "Documento",
                                              solo: "",
                                              readonly: "",
                                              "prepend-icon":
                                                "mdi-information-outline white--text",
                                              name: "documento_protestado",
                                              hint: "Informações não editáveis"
                                            },
                                            model: {
                                              value:
                                                _vm.apresentanteEntidade
                                                  .documento_cpf_cnpj,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.apresentanteEntidade,
                                                  "documento_cpf_cnpj",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                                            apresentanteEntidade.documento_cpf_cnpj\n                                        "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-3 px-3" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "4" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass: "font-weight-light"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "white" } },
                                                [_vm._v("mdi-chevron-right")]
                                              ),
                                              _vm._v("E-mail ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "8" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "E-mail",
                                              solo: "",
                                              readonly: "",
                                              "prepend-icon":
                                                "mdi-information-outline white--text",
                                              name: "email_protestado",
                                              hint: "Informações não editáveis"
                                            },
                                            model: {
                                              value:
                                                _vm.apresentanteEntidade.email,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.apresentanteEntidade,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "apresentanteEntidade.email"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mx-3 px-3" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "4" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass: "font-weight-light"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "white" } },
                                                [_vm._v("mdi-chevron-right")]
                                              ),
                                              _vm._v("Status ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "12", md: "8" } },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { color: "white" } },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  outlined: "",
                                                  color: "success",
                                                  label: "Status",
                                                  required: "",
                                                  "prepend-icon":
                                                    "mdi-information-outline white--text",
                                                  readonly: "",
                                                  disabled: "",
                                                  name: "status",
                                                  "data-cy": "status"
                                                },
                                                model: {
                                                  value:
                                                    _vm.apresentanteEntidade
                                                      .is_active,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.apresentanteEntidade,
                                                      "is_active",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                apresentanteEntidade.is_active\n                                            "
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { sm: "6", md: "12" } },
                                        [
                                          _c("v-card-text", [
                                            _vm._v(
                                              " Informações atualizadas através da plataforma Protestado. "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.ativoNotifica
                        ? _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.ativoNotifica,
                                  expression: "!ativoNotifica"
                                }
                              ],
                              staticClass: "mx-auto",
                              staticStyle: { display: "flex" },
                              attrs: { sm: "6", md: "6" }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mx-3",
                                  attrs: { heigth: "100%", color: "indigo" }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "white--text" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-3",
                                          attrs: {
                                            small: "",
                                            fab: "",
                                            color:
                                              "yellow lighten-2 indigo--text"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "indigo--text" },
                                            [_vm._v("mdi-information")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(
                                        "Apresentante não habilitado na Notifica. "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "white--text" },
                                    [
                                      _vm._v(
                                        " Clique no botão abaixo para habilitar apresentante na plataforma notifica "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "white--text" },
                                    [
                                      _vm._v(
                                        " Ao confirmar, verifique os dados de integração que serão gerados. "
                                      )
                                    ]
                                  ),
                                  !_vm.ativoNotifica
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "d-flex justify-center ma-3",
                                          attrs: { loading: _vm.loading },
                                          on: {
                                            click:
                                              _vm.habilitarApresentanteNotifica
                                          }
                                        },
                                        [_vm._v(" Habilitar Apresentante ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.ativoNotifica
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mx-auto",
                              staticStyle: { display: "flex" },
                              attrs: { sm: "6", md: "6" }
                            },
                            [
                              _vm.loadingData
                                ? _c(
                                    "v-card",
                                    {
                                      attrs: {
                                        width: "100%",
                                        color: "indigo white--text"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3 px-3" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "12", md: "12" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "font-weight-light"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "white" }
                                                    },
                                                    [_vm._v("mdi-chart-donut")]
                                                  ),
                                                  _vm._v(
                                                    " Buscando dados do apresentante ... "
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("v-progress-linear", {
                                                attrs: {
                                                  indeterminate: "",
                                                  color: "white"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.loadingData
                                ? _c(
                                    "v-card",
                                    {
                                      attrs: {
                                        width: "100%",
                                        color: "indigo white--text"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-3",
                                              attrs: { small: "", fab: "" }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("mdi-check")]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(
                                            " Esse é um apresentante ativo na Notifica "
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3 px-3" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "4" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "font-weight-light"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "white" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-chevron-right"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" Nome da Company ")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "8" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Nome da Company",
                                                  solo: "",
                                                  readonly: "",
                                                  "prepend-icon":
                                                    "mdi-information-outline white--text",
                                                  name: "notifica_company_name",
                                                  hint:
                                                    "Informações não editáveis"
                                                },
                                                model: {
                                                  value:
                                                    _vm.apresentanteNotifica
                                                      .name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.apresentanteNotifica,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "apresentanteNotifica.name"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3 px-3" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "4" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "font-weight-light"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "white" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-chevron-right"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" ID da Company ")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "8" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Id do Apresentante na Notifica(UUID)",
                                                  solo: "",
                                                  readonly: "",
                                                  "prepend-icon":
                                                    "mdi-information-outline white--text",
                                                  name: "notificaUuid",
                                                  hint:
                                                    "Informações não editáveis"
                                                },
                                                model: {
                                                  value:
                                                    _vm.apresentanteNotifica.id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.apresentanteNotifica,
                                                      "id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "apresentanteNotifica.id"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3 px-3" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "4" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "font-weight-light"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "white" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-chevron-right"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" Tipo Documento ")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "8" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "CPF/CNPJ",
                                                  solo: "",
                                                  readonly: "",
                                                  "prepend-icon":
                                                    "mdi-information-outline white--text",
                                                  name:
                                                    "notifica_document_type",
                                                  hint:
                                                    "Informações não editáveis"
                                                },
                                                model: {
                                                  value:
                                                    _vm.apresentanteNotifica
                                                      .document_type,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.apresentanteNotifica,
                                                      "document_type",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                            apresentanteNotifica.document_type\n                                        "
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3 px-3" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "4" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "font-weight-light"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "white" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-chevron-right"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" Documento ")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "8" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Documento habilitado",
                                                  solo: "",
                                                  readonly: "",
                                                  "prepend-icon":
                                                    "mdi-information-outline white--text",
                                                  name:
                                                    "notifica_document_number",
                                                  hint:
                                                    "Informações não editáveis"
                                                },
                                                model: {
                                                  value:
                                                    _vm.apresentanteNotifica
                                                      .document_number,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.apresentanteNotifica,
                                                      "document_number",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                            apresentanteNotifica.document_number\n                                        "
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3 px-3" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "4" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "font-weight-light"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "white" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-chevron-right"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" Email ")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "8" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "E-mail",
                                                  solo: "",
                                                  readonly: "",
                                                  "prepend-icon":
                                                    "mdi-information-outline white--text",
                                                  name: "email",
                                                  hint:
                                                    "Informações não editáveis"
                                                },
                                                model: {
                                                  value:
                                                    _vm.apresentanteNotifica
                                                      .email,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.apresentanteNotifica,
                                                      "email",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "apresentanteNotifica.email"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3 px-3" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "4" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "font-weight-light"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "white" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-chevron-right"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" Ativo ")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { sm: "6", md: "8" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Status",
                                                  solo: "",
                                                  readonly: "",
                                                  "prepend-icon":
                                                    "mdi-information-outline white--text",
                                                  name: "is_active",
                                                  hint:
                                                    "Informações não editáveis"
                                                },
                                                model: {
                                                  value:
                                                    _vm.apresentanteNotifica
                                                      .is_active,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.apresentanteNotifica,
                                                      "is_active",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                            apresentanteNotifica.is_active\n                                        "
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("CardConfigMensageria", {
                            attrs: {
                              cd_apresentante: _vm.apresentante.cd_apresentante
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.snackbar
                ? _c("Snackbar", {
                    attrs: {
                      mostrarSnackbar: _vm.snackbar,
                      corSnackbar: _vm.colorSnackbar,
                      mensagemSnackbar: _vm.mensagemSnackbar
                    },
                    on: {
                      fecharSnackbar: function($event) {
                        _vm.snackbar = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }